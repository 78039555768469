import React from 'react'
import L2 from './lotties/L2'
import L5 from './lotties/L5'
import L6 from './lotties/L6'
const Redes = () => {
  return (
    <div className='' style={{background: "linear-gradient(90.0deg, #212529 1.84%, #212020 102.67%)"}}>
        <h5 className='text-center text-white m-0'>Encontranos tambien en nuestras Redes Sociales</h5>
        <div className="container text-center">
            <div className="row">
                <div className="col-3">
                    
                </div>
                
                <div className="col-2">
                    <p className='text-white'>Instagram</p>
                    <a href="https://www.instagram.com/indalcesrl/"><L5/></a>
                    
                </div>
                <div className="col-2">
                    <p className='text-white'>Whats App</p>
                    <a href="https://www.facebook.com/profile.php?id=100063479105146"><L2/></a>
                </div>
                <div className="col-2">
                    <p className='text-white'>Facebook</p>
                    <a href="https://www.facebook.com/profile.php?id=100063479105146"><L6/></a>
                </div>
                <div className="col-3">
                    
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <p className='m-0 text-secondary'>Copyright - Todos los derechos reservados 2022 - Desarrollado por Rialto Estudio</p>
                </div>
            </div>
        </div>
        
        
    </div>
  )
}

export default Redes