import React from 'react'
import L3 from './lotties/L3'
import './Historia.css'
const Historia = () => {
  return (
    <div className='container-fluid' style={{background: "linear-gradient(45.5deg, #212529 50.84%, #525252 102.67%)"}}>
        
        <div className='row'>
        <h3 className='text-center text-white mt-5'>Historia</h3>
            <div className='col-sm-12 col-md-6'>
                <L3 />
            </div>
            <div className='col-sm-12 col-md-6'>
                <p className='text-white'><strong>Indalce SRL</strong> es una empresa dedicada a la fabricacion
                    y comercializacion de productos premoldeados de hormigon, desde el año 1970 
                    y dirigida por la tercera generacion de la familia fundadora.
                </p>
                <br />
                <br />
                <p className='text-white'>Ubicados aproximadamente a 200km. de la ciudad de Cordoba,
                    en la localidad de Justiniano Posse, hemos abastecido a las 
                    principales obras de la zona y el pais, fabricando con <strong>materiales de
                    primera linea</strong> y ofreciendo la mejor calidad en nuestros productos.
                </p>
            </div>
        </div>
    </div>
  )
}

export default Historia