import React from 'react'
import './Productos.css'
import imagenP from './imagenes/Portada.png'
import imagenPostes from './imagenes/Postes.png'
import L1 from './lotties/L1'
import L4 from './lotties/L4'
const Productos = () => {
  return (
    <div className='container-fluid' style={{background: "linear-gradient(180.97deg, #212529 1.84%, #515151 102.67%)"}}>
        <div className='row text-center'>
            <div className="col-12 columna">               
                <h3 className='text-center text-white mt-5'>Productos</h3>
            </div>       
            <div className='col-sm-12 col-md-12'>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='text-center text-white mt-5'>TUBOS</h4>
                        <hr/>
                    </div>
                    <div className='col-12'>
                    <img src={imagenP} className='imgH mt-5' alt="" />
                        <p className='text-justify text-white'>
                            Tubos premoldeados de hormigon para alcantarillas y cabezales completos.
                            <strong>Medidas disponibles:</strong>
                        </p>
                        <p className='text-justify'>
                            
                        </p>
                        <p className='text-justify text-white'>
                            400mm , 600mm, 800mm, 1000mm
                        </p>
                    </div>
                </div>
            </div>

            <div className='col-sm-12 col-md-12'>
                <div className='row'>
                    <div className='col-12'>
                        <h4 className='text-center text-white mt-5'>POSTES</h4>
                        <hr />
                        <img src={imagenPostes} className='imgP mt-5' alt="" />
                    </div>
                    <div className='col-12'>
                        <p className='text-center text-white'>
                            Postes premoldeados de hormigon para cercos olimpicos,
                            tejido y todos los accesorios.
                        </p>
                        
                        <p className='text-center text-white'>
                            Postes premoldeados de hormigón para alambrados rurales.
                        </p>
                        
                        
                    </div>
                </div>
            </div>
        </div>

        <div className="row m-5">
            <div className="col-12 justify-content-center">
                <h2 className='text-center text-white'>Servicio de Felete</h2>
                <h5 className='text-center text-white'>Y descarga propio.</h5>
                <hr />
                <L4 />
            </div>
        </div>

    </div>
  )
}

export default Productos