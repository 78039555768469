import React from 'react';
import Lottie from 'react-lottie';
import animationData from './5.json';
import './lottie.css'
export default function L5() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div className='m-0 p-0'>
        <Lottie 
          options={defaultOptions}
          height={50}
          width={50}
        />
      </div>
    );
  }