import './App.css';
import Header from './componentes/Header';
import Historia from './componentes/Historia';
import Productos from './componentes/Productos';
import Mapa from './componentes/Mapa';
import Navbar from './componentes/Navbar';
import Redes from './componentes/Redes';
function App() {
  return (
    <div className="App" style={{background: "linear-gradient(89.97deg, #212529 1.84%, #525252 102.67%)"}}>
      <header className="App-header">
        <Header />
        <Historia />
        <Productos />
        <Mapa />
        
        <Redes />
      </header>
    </div>
  );
}

export default App;
