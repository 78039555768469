import React from 'react'
import imagenL from './imagenes/Logopng.png'
import imagenP from './imagenes/Portada.png'
import './Header.css'
import L1 from './lotties/L1'
import L2 from './lotties/L2'
import Navbar from './Navbar'
const Header = () => {
  return (
  <>
    
      <img className='img1' src={imagenP} alt='Indalce' />
      <h3 className='text-white text-center m-4'>Experiencia y Calidad</h3>
      <p className='text-center mt-0 text-secondary'>Escribinos a Whats App</p>
        <a href="https://api.whatsapp.com/send?phone=5493537591610&text=Hola,%20los%20encontre%20en%20su%20sitio%20Web%20"><L2 /></a>
      <p className='text-center mt-0 text-secondary'>Llamanos al </p>
      <h3 className='text-center mt-0 mb-5 text-white'>03537 59-1610</h3>
  </>
  )
}

export default Header