import React from 'react'

const Navbar = () => {
  return (
    <div className='container-fluid text-center' style={{background: "linear-gradient(90.0deg, #212529 1.84%, #212020 102.67%)"}}>
      <div className='row-fluid'>
        <div className='col-12'>
          <ul class="nav justify-content-center">
            <li class="nav-item m-2">
              <a class="nav-link active text-white" aria-current="page" href="#Inicio">Inicio</a>
            </li>
            <li class="nav-item m-2">
              <a class="nav-link text-white" href="#Nosotros">Nuestra Empresa</a>
            </li>
            <li class="nav-item m-2">
              <a class="nav-link text-white" href="#Productos">Productos</a>
            </li>
            <li class="nav-item m-2">
              <a class="nav-link text-white" href="<Mapa/>">Contactanos</a>
            </li>
          </ul>
        </div>
      </div>
      </div>
  )
}

export default Navbar