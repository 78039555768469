import {React,iframe} from 'react'
import './Mapa.css'


const Mapa = () => {
  
  return (
    <div className='container-fluid text-center' name='mapa' id='mapa'>
      <h4 className='text-white m-3'>Ubicación</h4>
      <hr className='mb-5 text-center'></hr>
      <div className='row mb-5'>
        <div className='col-12'>
          <iframe className='responsive' 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.1822592719454!2d-62.681394398629486!3d-32.89064338416443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c951dbaf68b42f%3A0xd15301fa5d0f1fd!2sIndalce%20SRL!5e0!3m2!1ses-419!2sar!4v1659457197151!5m2!1ses-419!2sar" 
            width="80%" 
            height="350" 
            style={{border:"0"}} 
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  
    
  )
}

export default Mapa